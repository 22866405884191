import React, { useState } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Page from '@components/Page';
import { css } from '@emotion/react';
import { getPageElements } from '@lib/utils';
import { graphql } from 'gatsby';
import PlanCard from '@components/theme/PlanCard';

const PLANS = [
  {
    id: '1',
    title: 'Beverage cost-efficiency manager',
    short: 'Chcę obniżyć koszty związane ze sprzedażą napojów',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '2',
    title: 'Food cost-efficiency manager',
    short: 'Chcę obniżyć koszty związane ze sprzedażą dań',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '3',
    title: 'Hotel\'s cost-efficiency manager',
    short: 'Chcę kontrolować i ograniczyć koszty surowców w hotelu',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '4',
    title: 'Menu engineering manager',
    short: 'Chcę zarabiać więcej z menu engineeringiem',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '5',
    title: 'Autonomous Management',
    short: 'Chcę uniezależnić się od kompetencji moich pracowników ',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '6',
    title: 'Multi-venues cost-efficiency manager',
    short: 'Chcę mieć łatwą kontrolę nad wieloma punktami sprzedażowymi',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '7',
    title: 'Corporate Social Responsibility (CSR) Manager',
    short: 'Chcę mierzyć i redukować negatywny wpływ biznesu na środowisko',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '8',
    title: 'Certified 3rd party auditor',
    short: 'Chcę mieć niezależnego audytora w swoim lokalu',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  }
];

export default function Flow({
  data: { page },
  pageContext: { language, refs, layouts, configuration }
}) {
  const { body } = getPageElements(page);
  const [selectedPlans, setSelectedPlans] = useState([]);

  const toggleSelect = (id) => {
    if (selectedPlans.includes(id)) {
      setSelectedPlans(selectedPlans.filter((planId) => planId !== id));
    } else {
      setSelectedPlans([...selectedPlans, id]);
    }
  };

  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div
        className="py-5"
        css={css`
          h2,
          h3,
          h4 {
            margin-top: 1em;
          }
        `}
      >
        <div className="container">
          <h1 className="mb-4 text-center">{'Który problem chcesz rozwiązać?'}</h1>
          <div className="row">
            <div className="col-12 col-md-6">
              {PLANS.filter((plan, index) => index % 2 !== 1).map((plan) => (
                <PlanCard key={plan.id} selected={selectedPlans.includes(plan.id)} toggleSelect={() => toggleSelect(plan.id)} {...plan} />
              ))}
            </div>
            <div className="col-12 col-md-6">
              {PLANS.filter((plan, index) => index % 2 === 1).map((plan) => (
                <PlanCard key={plan.id} selected={selectedPlans.includes(plan.id)} toggleSelect={() => toggleSelect(plan.id)} {...plan} />
              ))}
            </div>
          </div>
          <div css={css`
          display: flex;
          width: 100%;
          justify-content: center;
        `}>
            <a href={`/businesscase?plans=${selectedPlans.join(',')}`} className={'btn btn-primary btn-lg shadow-sm'}>Wybierz</a>
          </div>
        </div>

      </div>

      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;